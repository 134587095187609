import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const GetMedia = () => {
    const { kode } = useParams();
    const [audioUrl, setAudioUrl] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAudio = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getmedia/${kode}`);
                setAudioUrl(response.data.audio_url);
            } catch (err) {
                setError('Audio not found');
                console.error('Error fetching audio:', err);
            }
        };
        fetchAudio();
    }, [kode]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-2xl font-semibold">Audio Player</h1>
            {error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                audioUrl && <audio controls src={audioUrl} className="mt-4" />
            )}
        </div>
    );
};

export default GetMedia;
