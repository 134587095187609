import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './index.css';
import BookContent from './BookContent';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/books`);
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching the books:', error);
      }
    };

    fetchBooks();
  }, []);

  return (
    <Router>
      <div className="bg-gray-100 min-h-screen">
        <header className="bg-white p-5 shadow-md flex justify-between items-center">
          <div className="flex items-center">
            <img src="https://atlaz-content.s3.ap-southeast-1.amazonaws.com/logo-new.png" alt="Logo" className="h-10" />
            {/* <h1 className="text-2xl font-bold ml-4">Book List</h1> */}
          </div>
          {/* <Link to="/" className="bg-blue-500 text-white py-2 px-4 rounded-lg">Home</Link> */}
        </header>
        <main className="p-5">
          <Routes>
            <Route
              path="/"
              element={
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6">
                  {books.map((book) => (
                    <Link
                      to={`/contents/${book.slug}`}
                      key={book.id}
                      className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                    >
                      <img
                        src={book.image_cover}
                        alt={book.name}
                        className="w-full object-cover"
                      />
                      <div className="p-4">
                        <h2 className="text-lg font-semibold">{book.name}</h2>
                      </div>
                    </Link>
                  ))}
                </div>
              }
            />
            <Route path="/contents/:slug" element={<BookContent />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
