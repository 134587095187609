import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const getEmbeddableUrl = (url) => {
  const youtubeShortLinkPattern = /^https:\/\/youtu\.be\/(.+)/;
  const youtubeFullLinkPattern = /^https:\/\/www\.youtube\.com\/watch\?v=(.+)/;

  let match = url.match(youtubeShortLinkPattern);
  if (match) {
    return `https://www.youtube.com/embed/${match[1]}`;
  }

  match = url.match(youtubeFullLinkPattern);
  if (match) {
    return `https://www.youtube.com/embed/${match[1]}`;
  }

  return url;
};

const BookContent = () => {
  const { slug } = useParams();
  const [content, setContent] = useState([]);
  const [subjectName, setSubjectName] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [mediaType, setMediaType] = useState('');
  const [mediaSrc, setMediaSrc] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contents/${slug}`);
        setContent(response.data);
        if (response.data.length > 0) {
          setSubjectName(response.data[0].subject_name);
        }
      } catch (error) {
        console.error('Error fetching the content:', error);
      }
    };

    fetchContent();
  }, [slug]);

  const openModal = (type, src) => {
    if (type === 'video') {
      src = getEmbeddableUrl(src);
    }
    setMediaType(type);
    setMediaSrc(src);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setMediaSrc('');
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold">{subjectName}</h2>
        <Link to="/" className="bg-blue-500 text-white py-2 px-4 rounded-lg">Home</Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Subject Name</th>
              <th className="py-2 px-4 border-b">Lesson Name</th>
              <th className="py-2 px-4 border-b">Media Title</th>
              <th className="py-2 px-4 border-b">Page</th>
              <th className="py-2 px-4 border-b">Video</th>
              <th className="py-2 px-4 border-b">Audio</th>
            </tr>
          </thead>
          <tbody>
            {content.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{item.subject_name}</td>
                <td className="py-2 px-4 border-b">{item.lesson_name}</td>
                <td className="py-2 px-4 border-b">{item.media_title}</td>
                <td className="py-2 px-4 border-b">{item.page}</td>
                <td className="py-2 px-4 border-b">
                  {item.video && (
                    <button
                      onClick={() => openModal('video', item.video)}
                      className="bg-blue-500 text-white py-1 px-3 rounded-lg"
                    >
                      Play Video
                    </button>
                  )}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.audio && (
                    <button
                      onClick={() => openModal('audio', item.audio)}
                      className="bg-green-500 text-white py-1 px-3 rounded-lg"
                    >
                      Play Audio
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Media Modal"
      >
        <button onClick={closeModal} className="absolute top-2 right-2 text-xl">&times;</button>
        {mediaType === 'video' && (
          <iframe
            width="560"
            height="315"
            src={mediaSrc}
            title="Video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        {mediaType === 'audio' && (
          <audio controls>
            <source src={mediaSrc} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </Modal>
    </div>
  );
};

export default BookContent;
